.main_container {
    margin: 24px;
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    /* min-width: 100%;
    width: fit-content; */
}

.text_field {
    background: #F7F7F7;
    width: 410px;
    border-radius: 4px;
}

.text_field fieldset {
    border: none;
}

.text_field .MuiOutlinedInput-root {
    height: 40px;
}

.stock_table tbody tr td.Full_Stock span {
    background: #e4fcd8;
    color: #438147;
}

.stock_table tbody tr td.Low_Stock span {
    background: #ffe5d4;
    color: #b08055;
}

.stock_table tbody tr td.Out_of_Stock span {
    background: #fed4d5;
    color: #c96058;
}

.stock_table tbody tr td.Low_Stock,
.stock_table tbody tr td.Out_of_Stock,
.stock_table tbody tr td.Out_of_Stock,
.stock_table tbody tr td.Full_Stock {
    padding: 0px;
}

.stock_table tbody tr td.Low_Stock span,
.stock_table tbody tr td.Out_of_Stock span,
.stock_table tbody tr td.Out_of_Stock span,
.stock_table tbody tr td.Full_Stock span {
    padding: 6px 16px;
    border-radius: 6px;
    min-width: 110px;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap
}

.details_container {
    padding: 24px 0;
}

.details_container h6 {
    font-family: gilroy-bold;
    font-size: 16px;
}

.details_container .title {
    margin-bottom: 24px;
}

.product_status_list {
    display: inline-flex;
}

.product_status_list .statusCard {
    width: 380px;
    height: 103px;
    padding: 12px;
    margin-right: 24px;
    display: inline-flex;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
}

.product_status_list .out-of-stock {
    background: #f7e0e0  !important;
}

.product_status_list .statusCard {
    background: #f7f0e0;
}

.out-of-stock.enabled{
    border: 1px solid rgb(209, 0, 0);
}

.low-stock.enabled{
    border: 1px solid rgb(237, 155, 0);
}

.all.enabled{
    border: 1px solid rgb(0, 193, 32);
}

.product_status_list .all {
    border-radius: 6px;
background: #E0F7E4;
}

.status_icon {
    width: 78px;
height: 71px;
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.status_icon svg {
    width: 40px;
    height: 25px;
}

.status_icon img {
    width: 20px;
    height: 20px;
}

.status_detail {
    display: flex;
    flex-flow: column;
    margin: 0 14px;
}

.status_detail h6 {
    font-family: gilroy-medium;
    font-size: 16px;
    /* font-weight: 500; */
}

.status_detail h5 {
    color: #535353;
    font-family: gilroy-bold;
    font-size: 18px;
    /* font-weight: 700; */
}

.status_card {
    width: 200px;
    padding: 12px;
    height: 80px;
    margin-right: 24px;
    background: #f7f7f7;
    display: inline-flex
}