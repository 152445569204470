@font-face {
    font-family: gilroy-regular;
    src: url("./assets/fonts/Gilroy/Gilroy-Regular.ttf");
}

@font-face {
    font-family: gilroy-bold;
    src: url("./assets/fonts/Gilroy/Gilroy-Bold.ttf");
}
@font-face {
    font-family: gilroy-heavy;
    src: url("./assets/fonts/Gilroy/Gilroy-Heavy.ttf");
}
@font-face {
    font-family: gilroy-light;
    src: url("./assets/fonts/Gilroy/Gilroy-Light.ttf");
}
@font-face {
    font-family: gilroy-medium;
    src: url("./assets/fonts/Gilroy/Gilroy-Medium.ttf");
}
@font-face {
    font-family: gilroy-extra-bold;
    src: url("./assets/fonts/Gilroy/Gilroy-ExtraBold.ttf");
}
