@import url('../../components/src/CommonStyles.css');
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
.noRecords{
   font-family: "gilroy-medium";
   font-size: 18px;
   display: flex;
   padding:20px;
   text-align:center;
}
.react-datepicker__day--in-range {
   background-color: purple;
   color: white; /* To ensure proper contrast with the background */
 }